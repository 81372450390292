.backgroundDiv {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
}
.loginLogo {
  width: 150px;
  margin: 20px auto;
  display: block;
  filter: brightness(0) invert(1);
}
.loginForm {
  width: 320px;
  height: fit-content;
  background-color: #fff;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding-bottom: 30px;
}
.loginForm div {
  background-color: #303248;
  color: #fff;
  border-radius: 5px 5px 0 0;
  padding: 15px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
}
.loginForm input {
  margin: 0 30px 20px 30px;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  border: #30324850 1px solid;
}
.loginForm button {
  margin: 0 30px;
  padding: 15px;
  background-color: #fc7271;
  color: #ffffff;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 150ms ease-in;
}
.loginForm button:hover {
  background-color: #cf5c5c;
}
