.pageHeader {
  width: 100%;
  text-align: center;
  font-size: 32px;
  padding: 40px 30px 80px 30px;
  color: #ffffff;
  background-color: #0000004f;
  background-size: cover;
  background-position: bottom;
}

.pageContent {
  position: relative;
  top: -36px;
  margin: 0 30px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.tableContainer {
  padding: 20px;
}

.tableWrapper {
  overflow-x: scroll;
}

.tableContainer table {
  width: 100%;
  border-spacing: 0;
}

.tableContainer table thead th {
  padding: 5px 10px;
  background-color: #7e86a3;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
}

.tableContainer table.subTable thead th {
  padding: 5px 10px;
  background-color: #e4e5e8;
  text-align: left;
  color: #b5b4b4;
  text-transform: uppercase;
  font-size: 14px;
}
.tableContainer table tbody td {
  padding: 15px;
  color: #303248;
  font-size: 14px;
}

.tableContainer table tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.tableContainer table tbody tr:nth-child(even) {
  background-color: #ffffff;
}
.tableContainer table.subTable tbody tr:nth-child(odd), .tableContainer table tbody tr.subtable {
  background-color: #fff9ea;
}

.tableContainer table.subTable tbody tr:nth-child(even), .tableContainer table tbody tr.subtable {
  background-color: #fffbf4;
}

.pageGrid {
  position: relative;
  top: -40px;
  margin: 0 30px;
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-gap: 20px;
}

.gridCard {
  background-color: #ffffff;
  border-radius: 10px;
}

.gridHead {
  border-radius: 10px 10px 0 0;
  background-color: #fc7271;
  color: #ffffff;
  padding: 12px 15px;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.gridHeadRight {
  color: #ffffff;
  text-align: right;
  font-weight: normal;
}

.gridHeadRight:hover {
  text-decoration: underline;
}

.gridSubhead {
  background-color: #fafafa;
  color: #303248;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: bold;
  border-left: #303248 4px solid;
}

.editarSubhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editarSubhead .editarBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.editarSubhead img {
  width: 15px;
}

.listPage {
  position: relative;
  top: -40px;
  margin: 0 30px;
}

.datosGrid {
  position: relative;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  font-size: 12px;
}
.datosGridComentarios {
  position: relative;
  margin: 20px;
  font-size: 12px;
}

.tableIcon {
  width: 20px;
  margin: 7px;
  cursor: pointer;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.contentButton {
  background-color: #99e666;
  color: #ffffff;
  padding: 8px 15px;
  width: fit-content;
  border-radius: 5px;
}

.contentButton img {
  height: 10px;
  margin-right: 10px;
}

.asignarButton {
  background-color: #fc7271;
  color: #ffffff;
  padding: 8px 15px;
  width: fit-content;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.asignarButton img {
  height: 10px;
  margin-right: 10px;
}

.titleContent {
  color: #303248;
  font-size: 24px;
  font-weight: bold;
  border-left: #fc7271 5px solid;
  padding-left: 10px;
  margin: 10px 0;
}

.nuevoAAFFForm {
  display: flex;
  flex-direction: column;
}
.nuevoAAFFForm select {
  width: 100%;
  padding: 8px 15px;
  border-bottom: #303248 1px solid;
}
.nuevoAAFFForm input{
  width: 100%;
  padding: 10px 15px;
  border-bottom: #303248 1px solid;
}

.nuevoAAFFForm input[type="submit"] {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #fc7271;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
}

.listadoBtn {
  padding: 5px 12px;
  color: #ffffff;
  background-color: #fc7271;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
  display: inline-block;
}

.descargarBtn {
  display: flex;
  align-content: center;
  color: #fc7271;
  margin-top: 15px;
  width: fit-content;
  cursor: pointer;
}

.descargarBtn img {
  height: 15px;
  margin-right: 10px;
}

.bigBtn {
  padding: 10px 15px;
  display: flex;
  align-content: center;
  background-color: #fc7271;
  width: fit-content;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  color: #ffffff !important;
  margin: 0 10px
}

.bigBtn img {
  height: 20px;
  margin-right: 15px;
  filter: brightness(0) invert(1);
}

.buscador {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.buscador button {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #fc7271;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
}
.buscador button:hover{
  background-color: #e75858;
}
.buscador input {
  padding: 10px 15px;
  border-radius: 5px;
  border: #7e86a3 1px solid;
  margin-right: 10px;
}
.buscador p {
  margin-left: 20px;
  padding: 10px 15px;
}
.buscador select {
  padding: 10px 15px;
  border-radius: 5px;
  border: #7e86a3 1px solid;
  margin-right: 10px;
}
.exportar{
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: row-reverse;
}
.hojaVisitaContainer {
  padding: 30px 50px;
}

.hojaVisitaContainer label {
  display: block;
  padding-bottom: 10px;
}

.hojaVisitaContainer input[type="text"],
.hojaVisitaContainer input[type="number"],
.hojaVisitaContainer input[type="date"], 
.hojaVisitaContainer select {
  background-color: #ffffff;
  width: 100%;
  padding: 10px 15px;
}

.hojaVisitaContainer input:disabled {
  background-color: #ffffff;
  color: #303248;
}

.hojaVisitaContainer img {
  width: 100%;
}

.hojaVisitaContainer h2 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.hojaVisitaContainer input[type="radio"] {
  margin-right: 10px;
}

.hojaVisitaContainer input[type="submit"] {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #fc7271;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
  margin: 20px 0 20px auto;
}

.radioGroup {
  margin-bottom: 30px;
}

.contentBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media print {
  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
@media (max-width: 768px) {
  .buscador{
    flex-direction: column;
  }
  .buscador input, .buscador select{
    margin-bottom: 10px;
  }
  .buscador button{
    margin-right: 10px;
  }
}