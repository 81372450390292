.incidencia-container {
    background: white;
    border-radius: 5px;
    margin-top: 80px;
    height: 250px;
}

.bgincidencia {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #7f86a4;
    margin: 8px;
    height: 93%;
    text-align: center;
}
.icono-content img{
    width: 120px;
}
.icono-content{
    margin-right: -50px;
}

.incidencia-txt {
    margin-bottom: 20px;
    color: white;
    font-size: 20px;
}

.btn-Secondary {
    background-color: #fc7271 !important;
}

.btn-Secondary:hover {
    background-color: #c51162 !important;
}

.provPageGrid {
    position: relative;
    top: -40px;
    margin: 0 30px;
    display: grid;
    grid-gap: 20px;
}

.detPageGrid{
    position: relative;
    display: grid;
    grid-template-columns: 4fr 4fr;
    grid-gap: 20px;
}