.successMsg {
  width: 320px;
  height: fit-content;
  background-color: #fff;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 50px 30px;
  text-align: center;
  font-size: 18px;
}

.successMsg img {
  height: 50px;
  margin-bottom: 30px;
}

.passAdvice{
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px 30px;
  text-align: center;
  font-size: 18px;
  height: 100%;
}